import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setRangeReceipts,
  getTotalAmountReceipts,
} from "domain/actions/receipt";
import Ranges from "components/static/ranges";
import RangeComponent from "components/static/ranges/components/Ranges.component";

function RangesPanel(props) {
  const [sum, setSum] = useState([null, null]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) return setLoading(true);
    console.log("sumDoc");
    props.setRangeReceipts("sumDoc", sum);
    setTimeout(() => props.getTotalAmountReceipts(), 300);
  }, [sum]);

  return (
    <Ranges className={props.className}>
      <RangeComponent
        label="Сумма"
        value={sum}
        onChange={setSum}
        className="p-d-flex p-col-12 p-p-0 p-jc-between"
      />
    </Ranges>
  );
}

export default connect(null, { setRangeReceipts, getTotalAmountReceipts })(
  RangesPanel
);
